import {
    Box, Button, Divider, Grid,
    Paper,
    Typography,
} from "@mui/material";
import constants from "../utils/vars";
import { useQuery } from "react-query";
import { BlogCard } from "../components/cards";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate()
    const myProfile = useQuery("myProfile", () =>
        fetch(`${ constants.apiURL }/users/blogger`).then((res) => res.json()))
    const allBlogs = useQuery("blogs", () =>
        fetch(`${ constants.apiURL }/blogs?limit=6&sortBy=created_at:desc`).then((res) => res.json())
    );
    const pinnedBlogs = useQuery("pinnedBlogs", () =>
        fetch(`${ constants.apiURL }/blogs?filters=pin:=:true&limit=6&sortBy=created_at:desc`).then((res) => res.json()))
    return (
        <Box
            sx={ {
                flexGrow: 1,
                px: constants.parentPaddingX,
            } }
        >
            {/* Greetings */ }
            <Typography
                variant="h6"
                sx={ {
                    py: 3,
                    fontWeight: "bold",
                } }
            >
                Good evening!
            </Typography>

            {/* Profile Overview */ }
            { myProfile.isLoading
                ? "Loading..."
                : myProfile.error
                    ? "error fetching data"
                    :
                    <Paper
                        elevation={ 2 }
                        sx={ {
                            display: "flex",
                        } }
                    >
                        <Box
                            component="img"
                            src={ myProfile.data.profile_photo }
                            alt="Sorry I'm shy"
                            sx={ {
                                flexGrow: 1,
                                maxHeight: 200,
                                maxWidth: 200,
                            } }
                        ></Box>
                        <Box
                            sx={ {
                                flexGrow: 4,
                                px: 2,
                                py: 1,
                            } }
                        >
                            { myProfile.data.bio }
                        </Box>
                    </Paper>
            }
            {/* Pinned Content Overview */ }
            <Divider sx={ {
                my: 2
            } }/>
            <Typography
                variant="h5"
                sx={ {
                    textAlign: "center",
                    fontWeight: "bold",
                    // textTransform: "uppercase"
                } }
            >
                Pinned Content
            </Typography>
            <Divider sx={ {
                my: 2
            } }/>
            <Paper elevation={ 2 }>
                { pinnedBlogs.isLoading
                    ? "Loading..."
                    : pinnedBlogs.error
                        ? "error fetching data"
                        : <Box
                            variant="outlined"
                            sx={ {
                                display: "flex",
                                py: 1,
                            } }
                        >
                            <Grid container spacing={ 2 }>
                                { pinnedBlogs.data.filter((value) => value.pin).map((blog) =>
                                    <Grid item xs={ 4 } key={blog.id}><BlogCard { ...blog }/></Grid>) }
                            </Grid>
                        </Box> }
                <Box sx={ {
                    p: 2,
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "right",
                    width: "inherit"
                } }>
                    <Button variant="neutral" onClick={ () => navigate('/blogs?filters=pin:=:true') }>More</Button></Box>
            </Paper>
            <Divider sx={ {
                my: 2
            } }/>
            <Typography
                variant="h5"
                sx={ {
                    textAlign: "center",
                    fontWeight: "bold",
                    // textTransform: "uppercase"
                } }
            >
                Latest Content
            </Typography>
            <Divider sx={ {
                my: 2
            } }/>
            <Paper elevation={ 2 }>
                {/* Latest Content */ }
                { allBlogs.isLoading
                    ? "Loading..."
                    : allBlogs.error
                        ? "error fetching data"
                        : <Box
                            variant="outlined"
                            sx={ {
                                display: "flex",
                                py: 1,
                            } }
                        >
                            <Grid container spacing={ 2 }>
                                { allBlogs.data.map((blog) =>
                                    <Grid item xs={ 4 } key={blog.id}><BlogCard { ...blog }/></Grid>) }
                            </Grid>
                        </Box> }
                <Box sx={ {
                    p: 2,
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "right",
                    width: "inherit"
                } }>
                    <Button variant="neutral" onClick={ (_) => navigate("/blogs") }>More</Button></Box>
            </Paper>
        </Box>
    );
};

export default Home;
