import {
    Card,
    Link,
    CardContent,
    CardActions,
    Typography,
    Button,
    Box,
    CardMedia,
    CardHeader, Divider
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { DateTime } from 'luxon';
import defaultImage from '../assets/images/default-image.png';

const BlogCard = (blog) =>
    (<Card
        key={ blog.id }
        sx={ {
            minWidth: 300,
            minHeight: 300,
            mx: 2,
        } }
    >
        <CardMedia
            alt='No image provided :('
            component="img"
            height="200"
            src={ blog.profilePhoto || defaultImage }
        />
        <CardContent>
            <Typography>{ blog.title }</Typography>
        </CardContent>
        <CardActions>
            <Box
                sx={ {
                    flexGrow: 1,
                } }>
                <Button
                    variant="neutral"
                    size="small"
                    href={ `/blogs/${blog.id}` }>
                    View
                </Button>
            </Box>
            <Typography>{
                DateTime.fromISO(blog.createdAt).toLocaleString(DateTime.DATETIME_MED)
            }</Typography>
        </CardActions>
    </Card>)

const ProjectCard = (project) =>
    (<Card
        key={ project.id }
        sx={ {
            minHeight: 300,
            height: 500,
            mx: 2,
        } }
    >
        <CardHeader
            title={ project.title }
            subheader={ DateTime.fromISO(project.createdAt).toLocaleString(DateTime.DATETIME_MED) }
        />
        <CardMedia
            alt={ project.title }
            component={ project.media.substring(project.media.lastIndexOf('.') + 1) === 'mp4' ? 'video' : 'img' }
            controls
            height="200"
            src={ project.media || defaultImage }
        />
        <CardContent sx={ {
            height: '25%'
        } }>
            <Box sx={ {} }>

                <Typography>{ project.description }</Typography>
                <Divider/>
                <Typography>
                    Status: { project.status }
                </Typography>
                <Divider/>
                { project.contributors.length > 0 ? <Typography>
                    Helping hands
                    { project.contributors.slice(0, 2).map((contributor) =>
                        <li>
                            {
                                contributor.substring(contributor.lastIndexOf('/') + 1)
                            }
                            <Link color="inherit" href={ contributor } target="_blank">
                                <LaunchIcon fontSize="small" sx={ {} }/>
                            </Link>
                        </li>)
                    }
                    {
                        project.contributors.slice(2).length > 0 ? <li>
                            and { project.contributors.slice(2).length } more
                        </li> : null
                    }
                </Typography> : null }
            </Box>
        </CardContent>

        <CardActions>
            <Button variant="neutral" size="small" href={ project.projectLink } target="_blank">
                <LaunchIcon/> Open in a New Tab
            </Button>
        </CardActions>
    </Card>)
export { BlogCard, ProjectCard }