import Home from "./pages/home";
import Blog from "./pages/blog";
import About from "./pages/about";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Splash from "./pages/splash";
import { ThemeProvider } from "@emotion/react";
import theme from "./utils/theme";
import MainAppBar from "./components/appBar";
import { QueryClient, QueryClientProvider } from "react-query";
import Project from "./pages/project";
import BlogContent from "./pages/blogContent";

function App() {
    const pages = [
        {
            title: "home",
            route: "/",
        },
        {
            title: "Blogs",
            route: "/blogs",
        },
        {
            title: "My Projects",
            route: "/projects",
        },
        {
            title: "About Me",
            route: "/about",
        },
    ];

    const queryClient = new QueryClient();

    return (
        // <Watermark content="Under development" font={ {
        //     fontSize: 30,
        //     color: "rgba(0, 0, 0, 0.25)",
        // } }>
            <ThemeProvider theme={ theme }>
                <QueryClientProvider client={ queryClient }>
                    <BrowserRouter>
                        <Routes>
                            <Route element={ <Splash/> }>
                                <Route path="/" element={ <MainAppBar pages={ pages }/> }>
                                    <Route path="/" element={ <Home/> }/>
                                    <Route path="/blogs" element={ <Blog/> }/>
                                    <Route path="/blogs/:id" element={ <BlogContent/> }/>
                                    <Route path="/projects" element={ <Project/> }/>
                                    <Route path="/about" element={ <About/> }/>
                                    <Route path="*" element={ <div>Page not Found</div> }/>
                                </Route>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </QueryClientProvider>
            </ThemeProvider>
        // </Watermark>
    );
}

export default App;
