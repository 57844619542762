import { Box, MenuItem, Paper, Select, Typography } from "@mui/material";
import constants from "../utils/vars";
import Grid from '@mui/material/Unstable_Grid2';
import { ProjectCard } from "../components/cards";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";


const Project = () => {
    const [sortBy, setSortBy] = useState("created_at:desc")
    const [searchParams] = useSearchParams()
    const filter = searchParams.get('filters')
    const query = filter === null ? "" : `filters=${ filter }`
    const projects = useQuery(["projects", sortBy], () =>
        fetch(`${ constants.apiURL }/projects?${ query }&sortBy=${ sortBy }`).then((res) => res.json()))
    const handleSortByChange = (event) => {
        setSortBy(event.target.value)
    }

    return <Box
        sx={ {
            px: constants.parentPaddingX,
        } }
    >
        <Paper sx={ {
            my: 2
        } }>
            <Grid container spacing={ 2 } sx={ {
                m: 1
            } }>
                <Grid item xs={ 4 } display="flex">
                    <Typography sx={ {
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        px: 1
                    } }>Sort by </Typography>
                    <Select variant="standard" value={ sortBy } label="sort by" onChange={ handleSortByChange }>
                        <MenuItem value="created_at:desc">Created At (Desc)</MenuItem>
                        <MenuItem value="created_at:asc">Created At (Asc)</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </Paper>

        {/* Projects */ }
        {
            projects.isLoading
                ? "Loading..."
                : projects.error
                    ? "error fetching data"
                    : <Grid container spacing={ 2 }>
                        { projects.data.map((project) => (
                            <Grid item xs={ 4 } key={ project.id }>
                                <ProjectCard { ...project }></ProjectCard>
                            </Grid>
                        )) }
                    </Grid>
        }
    </Box>
}

export default Project;
