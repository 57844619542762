import { React, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";


const Splash = () => {
    const [loaded, setLoaded] = useState(false)

    // use this to figure out if the user is logged in and to fetch other required data
    useEffect (() => {
        setTimeout(() => {
            setLoaded(true)
        }, 100)

    }, [])

    return loaded ? <Outlet /> : (<div>Loading...</div>);
};

export default Splash;