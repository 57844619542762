import { Box, Typography } from "@mui/material"
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import constants from '../utils/vars'
import { DateTime } from "luxon";
import MarkdownComponent from "../components/markdown";

const BlogContent = () => {
    const id = useParams().id

    const blog = useQuery(`blog_${ id }`, () =>
        fetch(`${ constants.apiURL }/blogs/${ id }`).then((res) => res.json()))

    return blog.isLoading
        ? "Loading..."
        : blog.error
            ? "error fetching data"
            : <Box sx={ {
                px: constants.parentPaddingX
            } }>
                <Typography variant='h3'> { blog.data.title }</Typography>
                <Typography
                    variant='small'><i>{ DateTime.fromISO(blog.data.created_at).toLocaleString(DateTime.DATETIME_MED) }</i></Typography>
                <MarkdownComponent data={ blog.data.content }/>
            </Box>
}

export default BlogContent;