import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { Avatar, Divider, Link } from '@mui/material';

function MainAppBar(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (page) => () => {
        navigate(page.route)
    }

    return (<div>
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" variant="" sx={{
                boxShadow: 1,
                pr: 10
            }}
            >
                <Toolbar>
                    <Avatar src='logo512.png'></Avatar>
                    <Button
                        variant="primary"
                        onClick={handleNavigation({ route: "/" })}
                    >
                        <Typography variant="h6" component="div" sx={{
                            fontWeight: "bold",
                            textTransform: "capitalize"
                        }}>
                            Kirubel Adamu
                        </Typography>
                    </Button>
                    <Box sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: "center"
                    }}>
                        {props.pages.map(page => (
                            <Button 
                            variant="h6" key={page.route} sx={{
                                borderBottom: location.pathname === page.route 
                                ? "grey solid": "none",
                                borderRadius: 0,
                                mx: 1,
                            }}
                                onClick={handleNavigation(page)}
                            >
                                <Typography sx={{
                                    fontWeight: "bold",
                                    textTransform: "capitalize"
                                }}>
                                    {page.title}
                                </Typography>
                            </Button>
                        ))}
                    </Box>
                    <Box hidden>
                    <Button color="inherit">
                        <Typography sx={{
                            fontWeight: "500",
                            textTransform: "capitalize"
                        }}>
                            Login
                        </Typography>
                    </Button>
                    <Button color="inherit">
                        <Typography sx={{
                            fontWeight: "500",
                            textTransform: "capitalize"
                        }}>
                            Register
                        </Typography>
                    </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar></Toolbar>
        </Box>
        <Outlet />
        <Divider sx={{my: 1}} />
        <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        sx = {{
            m: 1
        }}
        >&#169; Copyright 2023, Kirubel Adamu, Addis Ababa, Ethiopia
        <Link
         sx={{
             mx:1,
             color: "grey"
            }}
         href="https://www.google.com" 
         >Privacy policy</Link>
         </Box>
    </div>
    )
}

export default MainAppBar