import {
    Avatar,
    Box,
    Divider, Link,
    ListItemAvatar,
    Table,
    Typography
} from "@mui/material";
import constants from "../utils/vars";
import Grid from '@mui/material/Unstable_Grid2';
import { useQuery } from "react-query";
import { DateTime } from "luxon";

const About = () => {
    const userData = useQuery("userData", () =>
        fetch(`${ constants.apiURL }/users/blogger`).then((res) => res.json()))
    const socialMediaData = useQuery("socialMediaData", () =>
        fetch(`${ constants.apiURL }/social-medias`).then((res) => res.json()))
    const skillsData = useQuery("skillsData", () =>
        fetch(`${ constants.apiURL }/skills`).then((res) => res.json()))
    const workPlacesData = useQuery("workPlacesData", () =>
        fetch(`${ constants.apiURL }/work-places`).then((res) => res.json()))
    const certificatesData = useQuery("certificatesData", () =>
        fetch(`${ constants.apiURL }/certificates`).then((res) => res.json()))

    return (<Box
        // width={ "100%" }
        sx={ {
            px: constants.parentPaddingX,
            my: constants.aboutPagePaddingY,
        } }
    >

        {/*  */ }
        {
            userData.isLoading
                ? "Loading..."
                : userData.error
                    ? "error fetching data"
                    : <Grid container spacing={ 2 }>
                        <Grid xs={ 12 }>
                            <Divider sx={ {
                                my: 2
                            } }/>
                            <Typography variant="h6" sx={ {
                                textAlign: "center",
                                textTransform: "uppercase",
                                fontWeight: "bold"
                            } }>Overview</Typography>
                            <Divider sx={ {
                                my: 2
                            } }/>
                        </Grid>
                        <Grid
                            sx={ {} }
                            xs={ 3 }>
                            <Box
                                height={ "100%" }
                                width={ "100%" }
                                sx={ {
                                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                                } }
                            >
                                <img
                                    style={ {
                                        width: "inherit",
                                        height: "auto",

                                    } }
                                    src={ userData.data.profile_photo } alt={ "Where am I?" }/>
                                <Typography
                                    variant={ "h6" }
                                    sx={ {
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        textTransform: "uppercase",
                                    } }
                                >{ userData.data.name }</Typography>
                                <Divider
                                    sx={ {
                                        my: 2,
                                    } }
                                />
                                <Typography
                                    sx={ {
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    } }
                                >
                                    Contact Information
                                </Typography>
                                <Box
                                    sx={ {
                                        p: 2
                                    } }
                                >
                                    <Table>
                                        <tr>
                                            <td><b>Email</b></td>
                                            <td> { userData.data.email }</td>
                                        </tr>
                                        <tr>
                                            <td><b>Phone</b></td>
                                            <td>Not Available</td>
                                        </tr>
                                        <tr>
                                            <td><b>Address</b></td>
                                            <td> { userData.data.address }</td>
                                        </tr>
                                        <tr>
                                            <td><b>Post</b></td>
                                            <td>Not Available</td>
                                        </tr>
                                    </Table>
                                </Box>
                                <Divider
                                    sx={ {
                                        my: 2,
                                    } }
                                />
                                <Typography
                                    sx={ {
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    } }
                                >
                                    Social Media Links
                                </Typography>
                                {
                                    socialMediaData.isLoading
                                        ? "Loading..."
                                        : socialMediaData.error
                                            ? "error fetching data"
                                            :
                                            <Box
                                                sx={ {
                                                    p: 1
                                                } }
                                            >
                                                {
                                                    socialMediaData.data.map((link) => (
                                                        <td key={ link.id }>
                                                            <Link href={ link.url }>
                                                                <ListItemAvatar>
                                                                    <Avatar alt={ "no image" } src={ link.icon }/>
                                                                </ListItemAvatar>
                                                            </Link>
                                                        </td>
                                                    ))
                                                }
                                            </Box>
                                }
                            </Box>
                            <Box>
                            </Box>
                        </Grid>
                        <Grid xs={ 9 } sx={ {
                            px: 2,
                        } }>
                            <Box sx={ {
                                p: 2,
                                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)"
                            } }>
                                <Typography variant="h6" sx={ {
                                    fontWeight: "bold"
                                } }>
                                    Who am i?
                                </Typography>
                                <Typography
                                    sx={ {
                                        textAlign: "justify"
                                    } }
                                >
                                    {
                                        userData.data.bio
                                    }
                                </Typography>
                            </Box>
                            <Divider sx={ { my: 2 } }/>
                            <Box sx={ {
                                p: 2,
                                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)"
                            } }>
                                <Typography variant="h6" sx={ {
                                    fontWeight: "bold"
                                } }>
                                    My Skill Set
                                </Typography>
                                <Typography
                                    sx={ {
                                        textAlign: "justify"
                                    } }
                                >
                                    Describing all one's skills in such a small space is not practical. But here are the
                                    highlights.
                                    {
                                        skillsData.isLoading ? "Loading..." :
                                            skillsData.error ? "error fetching data" :
                                                skillsData.data.map(i => <li><b>{ i.name }</b> - { i.description }</li>)
                                    }
                                </Typography>
                            </Box>
                            <Divider sx={ { my: 2 } }/>
                            <Box sx={ {
                                p: 2,
                                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)"
                            } }>
                                <Typography variant="h6" sx={ {
                                    fontWeight: "bold"
                                } }>
                                    Certifications
                                </Typography>
                                <Typography
                                    sx={ {
                                        textAlign: "justify"
                                    } }
                                >
                                    {
                                        certificatesData.isLoading ? "Loading..." :
                                            certificatesData.error ? "error fetching data" :
                                                certificatesData.data.map(certificate =>
                                                    <li>
                                                        <b>{ certificate.name }</b> - { certificate.given_by } - ({
                                                        DateTime.fromISO(certificate.date).toLocaleString(DateTime.DATE_SHORT)
                                                    })
                                                    </li>)
                                    }
                                </Typography>
                            </Box>
                            <Divider sx={ { my: 2 } }/>
                            <Box sx={ {
                                p: 2,
                                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)"
                            } }>
                                <Typography variant="h6" sx={ {
                                    fontWeight: "bold"
                                } }>
                                    Other Resources
                                </Typography>
                                <Typography
                                    sx={ {
                                        textAlign: "justify"
                                    } }
                                >
                                    <li>Download <Link color="secondary"
                                                       href={ `${ constants.apiURL }/assets/${ userData.data.cv }` }>
                                        My CV</Link></li>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={ 12 }>
                            <Divider sx={ {
                                my: 2
                            } }/>
                            <Typography variant="h6" sx={ {
                                textAlign: "center",
                                fontWeight: "bold",
                                textTransform: "uppercase"
                            } }>Work Experience</Typography>
                            <Divider sx={ {
                                my: 2
                            } }/>
                            <Grid xs={ 12 }>
                                {
                                    workPlacesData.isLoading ? "Loading..." :
                                        workPlacesData.error ? "error fetching data" :
                                            workPlacesData.data.map(workPlace =>
                                                <Box sx={ {
                                                    p: 2,
                                                    mb: 2,
                                                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)"
                                                } }>
                                                    <Typography variant="h6" sx={ {
                                                        fontWeight: "bold"
                                                    } }>
                                                        { workPlace.title } - { workPlace.name } ({ DateTime.fromISO(workPlace.start_from).toLocaleString(DateTime.DATE_SHORT) } - { workPlace.till
                                                        ? DateTime.fromISO(workPlace.till).toLocaleString(DateTime.DATE_SHORT)
                                                        : "Present"
                                                    }) ({ workPlace.type })
                                                    </Typography>

                                                    <Typography>{ workPlace.description }</Typography>
                                                    <ul>
                                                        <Typography sx={ {
                                                            fontWeight: "bold",
                                                            fontStyle: "italic"
                                                        } }>Key Responsibilities</Typography>
                                                        {
                                                            workPlace.responsibilities.map(responsibility =>
                                                                <li>{ responsibility }</li>
                                                            )
                                                        }
                                                    </ul>
                                                    <ul>
                                                        <Typography sx={ {
                                                            fontWeight: "bold",
                                                            fontStyle: "italic"
                                                        } }>Accomplishments</Typography>
                                                        {
                                                            workPlace.accomplishments.map(accomplishment =>
                                                                <li>{ accomplishment }</li>
                                                            )
                                                        }
                                                    </ul>
                                                </Box>)
                                }
                            </Grid>
                        </Grid>
                        <Grid xs={ 12 } hidden>
                            <Typography variant="h6" sx={ {
                                textAlign: "center",
                                textTransform: "uppercase",
                                fontWeight: "bold"
                            } }>Personal Life</Typography>
                        </Grid>
                    </Grid>
        }
    </Box>)
}

export default About;