import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeKatex from "rehype-katex";

const MarkdownComponent = ({ data }) => {
    return (
        <ReactMarkdown
            children={ data }
            remarkPlugins={ [remarkGfm] }
            rehypePlugins={ [rehypeRaw, rehypeKatex] }
            components={ {
            } }
        />)
}

export default MarkdownComponent;